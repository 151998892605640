<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item>广告内容</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="overflow: auto">
      <el-row style="margin-bottom: 15px">
        <el-button @click="dialogVisible=true" size="mini" type="primary" >新增</el-button>
      </el-row>
      <!-- 分类搜索 -->
      <el-row>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="会员手机号"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="广告位置"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="广告展示城市"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--商品数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      
        ref="singleTable"
        highlight-current-row
        border
        row-key="id"
        stripe
      >
       <el-table-column prop="mobile" label="序号" width="80">
          <template> </template>
        </el-table-column>
        <el-table-column prop="name" label="广告标题" width="250" show-overflow-tooltip/>
        <el-table-column prop="nam" label="广告位置"  width="250" show-overflow-tooltip/>
        <el-table-column prop="mobile"  label="广告图片" width="250" show-overflow-tooltip>
          <template>
            <img style="margin: 0 0 0 20px" src="http://qny.fhby.fuhangbeiyue.com/fhjt/image/16049984361197783" alt="" width="50px"/>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="广告展示城市" width="250" show-overflow-tooltip/>
        <el-table-column prop="signTime" label="广告状态" width="250" show-overflow-tooltip/>
        <el-table-column label="操作" min-width="250" fixed="right">
         <el-button @click="dialogVisiblie = true" size="mini" type="primary">编辑</el-button>
          <el-button size="mini" type="success">启用</el-button>
          <el-button size="mini" type="danger">停用</el-button>
          <el-button size="mini" type="danger">删除</el-button>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加广告 -->
    <el-dialog
      width="30%"
      title="新增广告内容"
      :append-to-body="true"
      :visible.sync="dialogVisible"
    >
      <el-form :model="form">
        <el-form-item label="广告标题" required>
          <el-col class="mr15" :span="12">
            <el-input size="mini"  placeholder="请输入名称"    />
          </el-col>
        </el-form-item>
        <el-form-item label="广告位置" required>
          <el-col class="mr15" :span="12">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="广告图片" required>
          <img src="" alt="" />
        </el-form-item>
        <el-form-item label="跳转链接">
          <el-col class="mr15" :span="12">
            <el-input size="mini" placeholder="排序"   />
          </el-col>
        </el-form-item>
        <el-form-item label="广告展示城市">
          <el-col class="mr15" :span="12">
            <el-input size="mini"  placeholder="排序"   ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="显示状态" required>
          <el-radio v-model="radio" label="1">启用</el-radio>
          <el-radio v-model="radio" label="2">停用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑广告 -->
    <el-dialog
      width="30%"
      title="编辑广告内容"
      :append-to-body="true"
      :visible.sync="dialogVisiblie"
    >
      <el-form :model="form">
        <el-form-item label="广告标题" required>
          <el-col class="mr15" :span="12">
            <el-input  size="mini" placeholder="请输入名称"   ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="广告位置" required>
          <el-col class="mr15" :span="12">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="广告图片" required>
          <img src="" alt="" />
        </el-form-item>
        <el-form-item label="跳转链接">
          <el-col class="mr15" :span="12">
            <el-input size="mini" placeholder="排序"    ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="广告展示城市">
          <el-col class="mr15" :span="12">
            <el-input size="mini" placeholder="排序"   ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="显示状态" required>
          <el-radio v-model="radio" label="1">启用</el-radio>
          <el-radio v-model="radio" label="2">停用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisiblie: false,
      dialogVisible: false,
      tableData: [
        {
          name: 689689687698698,
          nam: "支付宝",
          money: 13645825685,
          signProvince: "杭州   余杭区",
        },
      ],
      loading: false,
      // 查找商品列表参数对象
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        time: "2021-04-23 11:18:54",
        province: "内蒙古自治区",
        city: "兴安盟",
      },
      total: 0,
    };
  },

  methods: {
    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style lang="scss">
.el-information {
  .el-col {
    margin-bottom: 15px;
  }
}
.abow_dialog {
  font-weight: bold;

  p {
    float: left;
  }
}

.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mr15 {
  margin-right: 15px;
}
</style>
